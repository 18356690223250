import pointer from './../images/Group 74.png';
// import Papaya from './../images/papaya01.png';
import gingerone from './../images/gingerone.png';
import gingertwo from './../images/gingertwo.png';
import eye from './../images/eye.png';
import heart from './../images/heart.png';
// import logo1 from './../images/SHABARI.AI_2-01.png';
import skin from './../images/star1.png';
import stomuch from './../images/stomuch.png';
import './Fruite-Details.css';
import logo from'./../images/SHABARI.AI_2-01.png';
import { React, useRef, useEffect, useState } from 'react';
const FruitDetails = (props) => {
    const [indictorePadding, setPadding] = useState(0);
    var indictoreValue = Number(props.fruitDetails.Sweetness);
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            console.log('width', ref.current.offsetWidth);
            setPadding(`${(ref.current.offsetWidth * indictoreValue) - ref.current.offsetWidth / 2 - 5}px`);
        }
    }, [indictoreValue]);
    if (props.fruitDetails.Maturity === "NaN-NaN-NaN"){
        var maturity = "Not Defined"
    }
    else{
        var maturity = props.fruitDetails.Maturity
    }
    console.log(props.fruitDetails.temp);
    if (props.fruitDetails.passedValue1 === "Ginger"){
        return(
            <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                {/* <div className="text-center logo">
                    <img src={logo}  style={{ width: '150px', height: '90px' }}/>
                </div> */}
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-31 pb-4 container" >
                                <div className='pt-text1'>
                                    <h3 className="text-left font-weight-bold ">Unveiling {props.fruitDetails.passedValue1}'s Geographic Roots</h3>
                                    <p>jkhgdd gfgd hgx gfx hggc bvn gncjbvcx {props.fruitDetails.temp}bvcb gfgfchm ngfdbx hg ghx gfnc gfd</p>

                                </div>
                                <div className='pt-text'>
                                </div>
                            </div>
                            <div className='result_heading'>        
                                <div className='circles'>
                                    <div className='pha'>
                                        <div className='circle1'>
                                            <h3>34</h3>
                                        </div>
                                        <h4>GINGEROL</h4>
                                        <p>Gingerol, the primary bioactive compound in ginger, offers anti-inflammatory, antioxidant, and therapeutic benefits.</p>
                                        <hr style={{ height:'2px', backgroundColor:' rgba(81, 21, 21, 0.28)' }}/>
                                        <p style={{color: 'rgba(113, 30, 30, 0.88)'}}>Heading Two</p>
                                        <hr style={{ height:'2px', backgroundColor:' rgba(81, 21, 21, 0.28)' }}/>
                                        <p style={{color: 'rgba(113, 30, 30, 0.88)'}}>Type One</p>
                                    </div>
                                    <div className='pha'>
                                        <div className='circle1'>
                                            <h3>9</h3>
                                        </div>
                                        <h4>FIBER</h4>
                                        <p>Ginger contains around 2 grams of dietary fiber per 100 grams, supporting digestion and promoting gut health.</p>
                                        <hr style={{ height:'2px', backgroundColor:' rgba(81, 21, 21, 0.28)' }}/>
                                        <p style={{color: 'rgba(113, 30, 30, 0.88)'}}>Heading One</p>
                                        <hr style={{ height:'2px', backgroundColor:' rgba(81, 21, 21, 0.28)' }}/>
                                        <p style={{color: 'rgba(113, 30, 30, 0.88)'}}>Type Two</p>
                                    </div>
                                    <div className='pha'>
                                        <div className='circle1'>
                                            <h3>2</h3>
                                        </div>
                                        <h4>GEOGRAPHY ROOT</h4>
                                        <p>Ginger's geographic roots trace to Southeast Asia, thriving in tropical and subtropical climates.</p>
                                        <hr style={{ height:'2px', backgroundColor:' rgba(81, 21, 21, 0.28)' }}/>
                                        <p style={{color: 'rgba(113, 30, 30, 0.88)'}}>Nadia from Nagaland</p>
                                        <hr style={{ height:'2px', backgroundColor:' rgba(81, 21, 21, 0.28)' }}/>
                                        <p style={{color: 'rgba(113, 30, 30, 0.88)'}}>Traditional Jhum cultivation</p>
                                    </div>
                                    
                                </div>
                                <div className='footer_text'>
                                    <p>This is the text about the ginger in the last</p>

                                </div>
                            </div>

                            


                            {/* <div className="container col-sm-12">
                                <div className="container col-sm-12 pb-3">
                                    <div className="row text-left">
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">Ripening</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Ripening}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-0">
                                            <div className="result-box pb-0" >
                                            <p className="m-0 pb-1 font-weight-bold color-green">SWEETNESS</p>
                                                <p className="m-0 pb-2 txt-indictor font-weight-bold color-green" data-number={indictoreValue}></p>
                                                <div className="row justify-content-md-center">
                                                    <div ref={ref} className="result-scale btn-scale-asc-1">1</div>
                                                    <div className="result-scale btn-scale-asc-2">2</div>
                                                    <div className="result-scale btn-scale-asc-3">3</div>
                                                    <div className="result-scale btn-scale-asc-4">4</div>
                                                    <div className="result-scale btn-scale-asc-5">5</div>
                                                    <div className="result-scale btn-scale-asc-6">6</div>
                                                    <div className="result-scale btn-scale-asc-7">7</div>
                                                    <div className="result-scale btn-scale-asc-8">8</div>
                                                    <div className="result-scale btn-scale-asc-9">9</div>
                                                    <div className="result-scale btn-scale-asc-10">10</div>
                                                </div>
                                                <div className='row'>
                                                    <img src={pointer} data-number={indictoreValue} style={{ 'paddingLeft': indictorePadding }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">VARIETY</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Maturity} <span className="font-size-small">
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row col-sm-12 pt-3 justify-content-md-center">
                                        <div className="col-sm-7 pt-3 text-left">
                                            <h5 className="font-weight-bold">NUTRIENT INFORMATION: {props.fruitDetails.passedValue1}</h5>
                                            <span className="color-red font-size-small">Every 100 gms of {props.fruitDetails.passedValue1} contain</span>
                                            <ul>
                                            <li>Calories: 60 Kcal</li>
                                            <li>Carbohydrates: 15g</li>
                                            <li>Fiber: 1.6 g</li>
                                            <li>Sugars: 13.7 g</li>
                                            <li>Protein: 0.8 g</li>
                                            <li>Vitamin C: 36.4 mg (61% of the Daily Value)</li>
                                            <li>Vitamin A: 54 IU (1% of the Daily Value)</li>
                                            <li>Potassium: 168 mg (4% of the Daily Value)</li>
                                            <li>Good source of antioxidants, such as vitamin A, vitamin C, and beta-carotene</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5 pt-3  text-left">
                                            <h5 className="font-weight-bold">DISCLAIMER</h5>
                                            <ul>
                                            <li>Mango tastes better if kept under ideal conditions including Temperature 25°C, Humidity@ 85%</li>
                                            <li>SHABARI.AI provides insights on the quality and purity of fruits using advanced AI models with a proven accuracy rate of 95%. However, for complete assurance, it is recommended to corroborate the results through a comprehensive analysis conducted at an NABL-certified laboratory</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className='col-sm-3'>
                                            <a href="/" className="btn btn-fruit col-sm-12 text-center font-weight-bold">
                                                Reset</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
    else if(props.fruitDetails.passedValue1 === "Mango"){
        
        return(
            <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                <div className="text-center logo">
                    {/* <p className="color-green">SHABARI.<span className="color-white">AI</span></p> */}
                    <img src={logo}  style={{ width: '220px', height: '130px' }}/>
                </div>
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-3 pb-4 container">
                                <h3 className="text-left font-weight-bold ">Know about Safeda {props.fruitDetails.passedValue1} - <span className='green-color'>SHABARI</span>.AI</h3>
                            </div>
                            <div className="container col-sm-12">
                                <div className="container col-sm-12 pb-3">
                                    <div className="row text-left">
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">Ripening</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Ripening}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-0">
                                            <div className="result-box pb-0" >
                                            <p className="m-0 pb-1 font-weight-bold color-green">SWEETNESS</p>
                                                <p className="m-0 pb-2 txt-indictor font-weight-bold color-green" data-number={indictoreValue}></p>
                                                <div className="row justify-content-md-center">
                                                    <div ref={ref} className="result-scale btn-scale-asc-1">1</div>
                                                    <div className="result-scale btn-scale-asc-2">2</div>
                                                    <div className="result-scale btn-scale-asc-3">3</div>
                                                    <div className="result-scale btn-scale-asc-4">4</div>
                                                    <div className="result-scale btn-scale-asc-5">5</div>
                                                    <div className="result-scale btn-scale-asc-6">6</div>
                                                    <div className="result-scale btn-scale-asc-7">7</div>
                                                    <div className="result-scale btn-scale-asc-8">8</div>
                                                    <div className="result-scale btn-scale-asc-9">9</div>
                                                    <div className="result-scale btn-scale-asc-10">10</div>
                                                </div>
                                                <div className='row'>
                                                    <img src={pointer} data-number={indictoreValue} style={{ 'paddingLeft': indictorePadding }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">VARIETY</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Maturity} <span className="font-size-small">
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row col-sm-12 pt-3 justify-content-md-center">
                                        <div className="col-sm-7 pt-3 text-left">
                                            <h5 className="font-weight-bold">NUTRIENT INFORMATION: {props.fruitDetails.passedValue1}</h5>
                                            <span className="color-red font-size-small">Every 100 gms of {props.fruitDetails.passedValue1} contain</span>
                                            <ul>
                                            <li>Calories: 60 Kcal</li>
                                            <li>Carbohydrates: 15g</li>
                                            <li>Fiber: 1.6 g</li>
                                            <li>Sugars: 13.7 g</li>
                                            <li>Protein: 0.8 g</li>
                                            <li>Vitamin C: 36.4 mg (61% of the Daily Value)</li>
                                            <li>Vitamin A: 54 IU (1% of the Daily Value)</li>
                                            <li>Potassium: 168 mg (4% of the Daily Value)</li>
                                            <li>Good source of antioxidants, such as vitamin A, vitamin C, and beta-carotene</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5 pt-3  text-left">
                                            <h5 className="font-weight-bold">DISCLAIMER</h5>
                                            <ul>
                                            <li>Mango tastes better if kept under ideal conditions including Temperature 25°C, Humidity@ 85%</li>
                                            <li>SHABARI.AI provides insights on the quality and purity of fruits using advanced AI models with a proven accuracy rate of 95%. However, for complete assurance, it is recommended to corroborate the results through a comprehensive analysis conducted at an NABL-certified laboratory</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className='col-sm-3'>
                                            <a href="/" className="btn btn-fruit col-sm-12 text-center font-weight-bold">
                                                Reset</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }


    else if(props.fruitDetails.passedValue1 === "Pineapple"){
        
        return(
            <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                {/* <div className="text-center logo">
                    <p className="color-green">SHABARI.<span className="color-white">AI</span></p>
                    <img src={logo}  style={{ width: '220px', height: '130px' }}/>
                </div> */}
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    {/* <div className="modal-content"> */}
                        <div className="modal-body">
                            <div className="pt-3 pb-4 container">
                                <h3 style={{color:"white"}}>Prediction of Pineapple is not yet implemented.</h3>
                            </div>
                            {/* <div className="container col-sm-12">
                                <div className="container col-sm-12 pb-3">
                                    <div className="row text-left">
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">Ripening</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Ripening}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-0">
                                            <div className="result-box pb-0" >
                                            <p className="m-0 pb-1 font-weight-bold color-green">SWEETNESS</p>
                                                <p className="m-0 pb-2 txt-indictor font-weight-bold color-green" data-number={indictoreValue}></p>
                                                <div className="row justify-content-md-center">
                                                    <div ref={ref} className="result-scale btn-scale-asc-1">1</div>
                                                    <div className="result-scale btn-scale-asc-2">2</div>
                                                    <div className="result-scale btn-scale-asc-3">3</div>
                                                    <div className="result-scale btn-scale-asc-4">4</div>
                                                    <div className="result-scale btn-scale-asc-5">5</div>
                                                    <div className="result-scale btn-scale-asc-6">6</div>
                                                    <div className="result-scale btn-scale-asc-7">7</div>
                                                    <div className="result-scale btn-scale-asc-8">8</div>
                                                    <div className="result-scale btn-scale-asc-9">9</div>
                                                    <div className="result-scale btn-scale-asc-10">10</div>
                                                </div>
                                                <div className='row'>
                                                    <img src={pointer} data-number={indictoreValue} style={{ 'paddingLeft': indictorePadding }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1">
                                                <p className="m-0 pb-1 font-weight-bold color-green">VARIETY</p>
                                                <p>{props.fruitDetails && props.fruitDetails.Maturity} <span className="font-size-small">
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="container">
                                    <div className="row col-sm-12 pt-3 justify-content-md-center">
                                        <div className="col-sm-7 pt-3 text-left">
                                            <h5 className="font-weight-bold">NUTRIENT INFORMATION: {props.fruitDetails.passedValue1}</h5>
                                            <span className="color-red font-size-small">Every 100 gms of {props.fruitDetails.passedValue1} contain</span>
                                            <ul>
                                            <li>Calories: 60 Kcal</li>
                                            <li>Carbohydrates: 15g</li>
                                            <li>Fiber: 1.6 g</li>
                                            <li>Sugars: 13.7 g</li>
                                            <li>Protein: 0.8 g</li>
                                            <li>Vitamin C: 36.4 mg (61% of the Daily Value)</li>
                                            <li>Vitamin A: 54 IU (1% of the Daily Value)</li>
                                            <li>Potassium: 168 mg (4% of the Daily Value)</li>
                                            <li>Good source of antioxidants, such as vitamin A, vitamin C, and beta-carotene</li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-5 pt-3  text-left">
                                            <h5 className="font-weight-bold">DISCLAIMER</h5>
                                            <ul>
                                            <li>Mango tastes better if kept under ideal conditions including Temperature 25°C, Humidity@ 85%</li>
                                            <li>SHABARI.AI provides insights on the quality and purity of fruits using advanced AI models with a proven accuracy rate of 95%. However, for complete assurance, it is recommended to corroborate the results through a comprehensive analysis conducted at an NABL-certified laboratory</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className='col-sm-3'>
                                            <a href="/" className="btn btn-fruit col-sm-12 text-center font-weight-bold">
                                                Reset</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
            
        );
    }









    else{
        // let currentDate = new Date();
        // currentDate.setDate(currentDate.getDate() + props.fruitDetails.Maturity);
        // let day = currentDate.getDate();
        // let month = currentDate.getMonth() + 1;
        // let year = currentDate.getFullYear();
        // var date;
        // var ripening=props.fruitDetails.Ripening;
        // // date = day+'-'+month+'-'+year;
        
        // if(isNaN(day)){
        //     date = "Not Applicable";
        // }
        // else{
        //     if (props.fruitDetails.temp<=20){
        //         // console.log("temperature is :", props.FruitDetails.temp);
        //         if(ripening==="Conventional"){
        //             if(props.fruitDetails.Maturity===3 || props.fruitDetails.Maturity===2 || props.fruitDetails.Maturity===1){
        //                 indictoreValue = indictoreValue+1;
        //                 currentDate.setDate(currentDate.getDate() - 1);
        //             }
        //             else{
        //                 indictoreValue=indictoreValue;
        //                 currentDate.setDate(currentDate.getDate());
        //             }

        //         }
        //         else if(ripening==="Natural"){
        //             if(props.FruitDetails.Maturity===4 || props.FruitDetails.Maturity===3 || props.FruitDetails.Maturity===2 || props.FruitDetails.Maturity===1){
        //                 indictoreValue = indictoreValue+1;
        //                 currentDate.setDate(currentDate.getDate());
        //             }
        //             else{
        //                 indictoreValue=indictoreValue;
        //                 currentDate.setDate(currentDate.getDate());
        //             }

        //         }
        //         else if (ripening==="Over_ripened"){
        //             ripening= "Unknown";
        //             indictoreValue = 0;
        //             date = "May not be fit to eat.";
        //         }
        //         // else{
        //         //     ripening = ripening;
        //         //     indictoreValue = indictoreValue;
        //         //     date = date;
        //         // }
        //     }
        //     else{
        //         // let ripening=props.fruitDetails.Ripening;
        //         if (ripening==="Over_ripened"){
        //             ripening= "Unknown";
        //             indictoreValue = 0;
        //             date = "May not be fit to eat.";
        //         }
        //     }
        //     let day = currentDate.getDate();
        //     let month = currentDate.getMonth() + 1;
        //     let year = currentDate.getFullYear();
        //     var date;
        //     date = day+"-"+month+"-"+year;
        // }
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + props.fruitDetails.Maturity);
        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear();

        let date;
        let ripening = props.fruitDetails.Ripening;

        // Check if day is NaN
        if (isNaN(day)) {
            date = "Not Applicable";
        } else {
            if (ripening === "Over_ripened") {
                ripening = "Unknown";
                indictoreValue = 0;
                date = "May not be fit to eat."; // Updated here
            } else if (props.fruitDetails.temp <= 20) {
                if (ripening === "Conventional" && [1, 2, 3].includes(props.fruitDetails.Maturity)) {
                    indictoreValue += 1;
                    currentDate.setDate(currentDate.getDate() - 1);
                } else if (ripening === "Natural" && [1, 2, 3, 4].includes(props.fruitDetails.Maturity)) {
                    indictoreValue += 1;
                }
            }

            // Set the date only if ripening is not "Over_ripened"
            if (ripening !== "Unknown") {
                day = currentDate.getDate();
                month = currentDate.getMonth() + 1;
                year = currentDate.getFullYear();
                date = `${day}-${month}-${year}`;
            }
        }

        console.log("Date:", date);

        
    return (
        <div className={`container-fluid uploader bg-image-${props.passedValue}`}>
            <div className="modal" tabIndex={-1} style={{ display: 'block' }}>
                <div className="text-center logo">
                    {/* <p className="color-green">SHABARI.<span className="color-white">AI</span></p> */}
                    <img src={logo}  style={{ width: '220px', height: '130px' }}/>
                </div>
                <div className="modal-dialog  modal-xl modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="pt-3 pb-4 container">
                                <h3 className="text-left font-weight-bold " style={{fontSize:"18px"}}>Know about {props.fruitDetails.passedValue1} - <span className='green-color'>SHABARI</span>.AI</h3>
                            </div>
                            <div className="container col-sm-12">
                                <div className="container col-sm-12 pb-3">
                                    <div className="row text-left">
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1" style={{fontSize:"13px", height:"100px"}}>
                                                <p className="m-0 pb-1 font-weight-bold color-green">Ripening</p>
                                                {/* <p>{props.fruitDetails && props.fruitDetails.Ripening}</p> */}
                                                <p>{props.fruitDetails && ripening}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-0">
                                            <div className="result-box pb-0" style={{fontSize:"13px", height:"100px"}}>
                                                <p className="m-0 pb-1 font-weight-bold color-green">SWEETNESS :- <span className="m-0 pb-2 txt-indictor font-weight-bold color-green" data-number={indictoreValue}></span></p>
                                                <div className="row justify-content-md-center">
                                                    <div ref={ref} className="result-scale btn-scale-asc-1">1</div>
                                                    <div className="result-scale btn-scale-asc-2">2</div>
                                                    <div className="result-scale btn-scale-asc-3">3</div>
                                                    <div className="result-scale btn-scale-asc-4">4</div>
                                                    <div className="result-scale btn-scale-asc-5">5</div>
                                                    <div className="result-scale btn-scale-asc-6">6</div>
                                                    <div className="result-scale btn-scale-asc-7">7</div>
                                                    <div className="result-scale btn-scale-asc-8">8</div>
                                                    <div className="result-scale btn-scale-asc-9">9</div>
                                                    <div className="result-scale btn-scale-asc-10">10</div>
                                                </div>
                                                <div className='row'>
                                                    <img src={pointer} data-number={indictoreValue} style={{ 'paddingLeft': indictorePadding}} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-0">
                                            <div className="result-box result-box-1" style={{fontSize:"13px", height:"100px"}}>
                                                <p className="m-0 pb-1 font-weight-bold color-green">Best Taste</p>
                                                <p>{date} <span className="font-size-small">
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{marginTop:"-13px"}}/>
                                <div className="container">
                                    <div className="row col-sm-12 pt-3 justify-content-md-center">
                                        <div className="col-sm-7 pt-3 text-left">
                                            <h5 className="font-weight-bold" style={{fontSize:"12px"}}>NUTRIENT INFORMATION: {props.fruitDetails.passedValue1}</h5>
                                            {/* <span className="color-red font-size-small" style={{fontSize:"12px"}}>Every 100 gms of {props.fruitDetails.passedValue1} contain</span> */}
                                            <ul>
                                            <h5 style={{fontSize:"12px"}}>A Tropical Powerhouse</h5>
                                                <li><p style={{fontSize:"12px"}}>A vibrant fruit from Mexico, packed with nutrients for your health, vision, skin, and more.</p></li>
                                                <hr></hr>
                                                <h5 style={{fontSize:"16px"}}>Key Benefits</h5>
                                                <h6 style={{ color: 'red' }}><img src={heart} alt="Logo" style={{ width: '20px', height: '20px' }} />Heart Health :- <span style={{color:'black', fontSize:'10px'}}>Lycopene and potassium lower heart disease and stoke risk.</span> </h6>
                                                {/* <li><p>Lycopene and potassium lower heart disease and stoke risk.</p></li> */}
                                                <h6 style={{ color: 'green' }}><img src={stomuch} alt="eye" style={{ width: '20px', height: '20px' }} /> Digestive Boost :- <span style={{color:'black', fontSize:'10px'}}>High in fiber and water for smooth digestion and relief from constipation.</span></h6>
                                                {/* <li><p>High in fiber and water for smooth digestion and relief from constipation.</p></li> */}
                                                <h6 style={{ color: 'brown' }}><img src={skin} alt="Logo" style={{ width: '20px', height: '20px' }} />Radiant Skin :- <span style={{color:'black', fontSize:'10px'}}>Vitamin C enhance collagen for glowing skin and healthy hair.</span></h6>
                                                {/* <li><p>Vitamin C enhance collagen for glowing skin and healthy hair.</p></li> */}
                                                <h6 style={{ color: 'blue' }}><img src={eye} alt="Logo" style={{ width: '20px', height: '20px' }} />Healthy Eyes :- <span style={{color:'black', fontSize:'10px'}}>Carotene and zeaxanthin improve vision and protect against blue light.</span></h6>
                                                {/* <li><p>Carotene and zeaxanthin improve vision and protect against blue light</p></li> */}
                                                
                                            </ul>
                                        </div>
                                        <div className="col-sm-5 pt-3  text-left">
                                            <h5 className="font-weight-bold" style={{fontSize:"12px"}}>DISCLAIMER</h5>
                                            <ul style={{fontSize:"12px"}}>
                                                <li>{props.fruitDetails.passedValue1} Tastes better if kept under ideal condition including Temperature 20° C,
                                                    Humidity 75%.</li>
                                                <li>SHABARI.Al provides insights on the quality and purity of fruits using advanced Al models with
                                                    a proven accuracy rate of 95%.However, for complete assurance, it is recommended tocorroborate
                                                    the results through a comprehencive analysis conducted at an NABL-certified laboratory.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                        <div className='col-sm-3'>
                                            <a href="/" className="btn btn-fruit col-sm-12 text-center font-weight-bold">
                                                Reset</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );}}
export default FruitDetails;
